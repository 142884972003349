<template>
  <div class="zui-language" :class="{zui_button_btna:countText == countText1}" @click="onClick">
    <slot></slot>
  </div>
</template>
<script>
import './scss/language.scss'

export default {
    props: {
        countText: {
            default: ''
        },
        countText1: {
            default: ''
        },
    },
    methods: {
        onClick (e) {
            console.log('e',e)
            this.$emit('click', e)
        }
    }
}
</script>

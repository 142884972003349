<template>
    <div id="app">
        <div class="zui-header">
            <logo type="xiaoyanyun" href="/HomePage"></logo>
            <div class="flex-between f-r">
                <drop-list :config="configData" ref="droplist" v-if="droplistShow"></drop-list>
                <zui-button count-text="首页" :count-text1='$store.state.countText' @click="onButtonClick('首页')">{{ $t('menu.home') }}</zui-button>
                <zui-button count-text="关于我们" :count-text1='$store.state.countText' @click="onButtonClick('关于我们')">{{ $t('menu.about') }}</zui-button>
                <zui-button count-text="产品推荐" :count-text1='$store.state.countText' @click="onButtonClick('产品推荐')">{{ $t('menu.recommendation') }}</zui-button>
                <zui-button count-text="新闻中心" :count-text1='$store.state.countText' @click="onButtonClick('新闻中心')">{{ $t('menu.news') }}</zui-button>
                <zui-button count-text="产品查询" :count-text1='$store.state.countText' @click="onButtonClick('产品查询')">{{ $t('menu.inquiry') }}</zui-button>
                <zui-button count-text="资料下载" :count-text1='$store.state.countText' @click="onButtonClick('资料下载')">{{ $t('menu.download') }}</zui-button>
                <zui-button count-text="联系我们" :count-text1='$store.state.countText' @click="onButtonClick('联系我们')">{{ $t('menu.us') }}</zui-button>
                <div class="flex-j-center w-200 w-60">
                    <zui-language count-text="zh" :count-text1='$store.state.language' @click="clickLang('zh')">简</zui-language>
                    <zui-language count-text="en" :count-text1='$store.state.language' @click="clickLang('en')">EN</zui-language>
                </div>
                <div class="zui-rightcol mr-10" @click="openMenu" style="color: #0b8ba5;">◄目录►</div>
                <zui-phone></zui-phone>
            </div>
        </div>
        <div class="app-contant">
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
        <zui-footer/>
        <div class="topBarBox" v-if="top" @click="topClick">
            <img src="./views/img/top.png">
            TOP
        </div>
    </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.scss'
import Logo from './components/Logo'
import Page from './components/Page'
// import ZuiHeader from './components/Header'
import ZuiFooter from './components/Footer'
import ZuiButton from './components/Button'
import ZuiLanguage from './components/Language'
import ZuiPhone from './components/phone'
import DropList from 'vue-droplist'
import Util from './Util'

export default {
    components: {Page, Logo, ZuiFooter, ZuiButton, ZuiLanguage, ZuiPhone, DropList},
    name: 'App',
    data() {
        return {
            configData: {
                // position: {  // 设置显示位置，position
                //     top: '60px',
                //     right: '15px',
                //     bottom: '',
                //     left: ''
                // },
                // width: '40%', // 设置宽度
                // list: [ // 设置下拉列表数据和对应的点击事件
                //     {text: this.$t('menu.home'), action: this.gohome},
                //     {text: this.$t('menu.about'), action: this.goservice},
                //     {text: this.$t('menu.recommendation'), action: this.gocase},
                //     {text: this.$t('menu.news'), action: this.goplan},
                //     {text: this.$t('menu.inquiry'), action: this.goInquire},
                //     {text: this.$t('menu.download'), action: this.goDownload},
                //     {text: this.$t('menu.us'), action: this.golink}
                // ]
            },
            top: false,
            droplistShow: false
            // countText1:'首页'
        }
    },
    created() {
    },
    mounted() {
        // pageResize();
        this.Interface()
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
        if (this.$store.state.language) {
            this.$i18n.locale = this.$store.state.language
            setTimeout(() => {
                this.langChange()
            }, 200)
        } else {
            this.$i18n.locale = 'zh'
            setTimeout(() => {
                this.langChange()
            }, 200)
        }

    },
    methods: {
        langChange() {
            this.configData = {
                position: {  // 设置显示位置，position
                    top: '60px',
                    right: '15px',
                    bottom: '',
                    left: ''
                },
                width: '40%', // 设置宽度
                list: [ // 设置下拉列表数据和对应的点击事件
                    {text: this.$t('menu.home'), action: this.gohome},
                    {text: this.$t('menu.about'), action: this.goservice},
                    {text: this.$t('menu.recommendation'), action: this.gocase},
                    {text: this.$t('menu.news'), action: this.goplan},
                    {text: this.$t('menu.inquiry'), action: this.goInquire},
                    {text: this.$t('menu.download'), action: this.goDownload},
                    {text: this.$t('menu.us'), action: this.golink}
                ],
            }
            this.droplistShow = true
        },
        clickLang(e) {
            this.$i18n.locale = e
            this.$store.commit('setLanguage', e)
            window.location.reload(true);
        },
        // 获取页面滚动距离
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop > 200) {
                this.top = true;
            } else {
                this.top = false;
            }
        },
        topClick() {
            let Top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // 设置计时器，实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = Top -= 50
                if (Top <= 10) {
                    clearInterval(timeTop)
                }
            }, 20)
        },
        async Interface() {
            //公司信息
            await this.$axios.post('pcapi/index/index').then(res => {
                if (res.data.code == 1) {
                    this.$store.commit('setfrom', res.data.data)
                }
            })
        },
        onButtonClick: function (e) {
            if (e == "首页") {
                this.$router.push({
                    name: 'HomePage'
                })
            } else if (e == "关于我们") {
                this.$router.push({
                    name: 'AboutUs'
                })
            } else if (e == "产品推荐") {
                this.$router.push({
                    name: 'Recommended'
                })
            } else if (e == "新闻中心") {
                this.$router.push({
                    name: 'NewsCenter'
                })
            } else if (e == "产品查询") {
                this.$router.push({
                    name: 'Inquire'
                })
            } else if (e == "资料下载") {
                this.$router.push({
                    name: 'Download'
                })
            } else {
                this.$router.push({
                    name: 'Connection'
                })
            }
            this.$store.commit('setcountText', e)
            window.scrollTo(0, 0);
        },
        openMenu: function () {
            this.$refs.droplist.show()
        },
        //首页点击
        gohome: function () {
            this.$router.push({
                name: 'HomePage'
            })
            window.scrollTo(0, 0);
        },
        //关于我们
        goservice: function () {
            this.$router.push({
                name: 'AboutUs'
            })
            window.scrollTo(0, 0);
        },
        //新闻中心
        goplan: function () {
            this.$router.push({
                name: 'NewsCenter'
            })
            window.scrollTo(0, 0);
        },
        //产品推荐
        gocase: function () {
            this.$router.push({
                name: 'Recommended'
            })
            window.scrollTo(0, 0);
        },
        //产品查询
        goInquire: function () {
            this.$router.push({
                name: 'Inquire'
            })
            window.scrollTo(0, 0);
        },
        //资料下载
        goDownload: function () {
            this.$router.push({
                name: 'Download'
            })
            window.scrollTo(0, 0);
        },
        //联系我们
        golink: function () {
            this.$router.push({
                name: 'Connection'
            })
            window.scrollTo(0, 0);
        },
    }
}
</script>

<style lang="scss">
@import "./assets/css/common";
@import "./assets/css/main";

.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.topBarBox {
    width: 60px;
    height: 60px;
    background: #00B5E2;
    box-shadow: 0px 0px 10px 0px rgba(140, 140, 140, 0.5);
    border-radius: 50%;
    //display: flex;
    //align-items: center;
    //flex-wrap: wrap;
    //justify-content: center;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    position: fixed;
    right: 50px;
    bottom: 50px;
    text-align: center;
    padding: 10px;
    cursor: pointer;

    img {
        width: 27px;
        height: 23px;
        display: block;
        margin: 0 auto;
    }
}

.zui-header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: #FFFFFF;
    z-index: 999;

    .w-200 {
        width: 200px;
    }

    .zui-language {
        margin-right: 20px;
    }

    .zui-logo {
        width: 175px;
        margin: 0 0 0 160px;
    }

    .zui-button {
        line-height: 40px;
        background: #ffffff;
        //min-width: 120px;
        border-radius: 10px;
        color: #333333;
        padding: 0 20px;
    }

    .zui-rightcol {
        display: none;
    }
}

#app {

}

.app-contant {
    width: 100%;
    padding-top: 100px;
}

::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0; /*高宽分别对应横竖滚动条的尺寸*/
}

@media all and (max-width: 420px) {
    .app-contant {
        width: 100%;
        padding-top: 60px;
    }
    .zui-header {
        width: 100%;
        height: 60px;

        .mr-10 {
            margin-right: 10px;
        }

        .w-60 {
            width: 60px;
        }

        .zui-logo {
            width: 90px;
            margin: 0 0 0 10px;
        }

        .zui-language {
            margin-right: 10px;
            font-size: 14px;
        }

        .zui-button {
            display: none;
        }

        .zui-rightcol {
            display: flex;
            line-height: 30px;
            min-width: 50px;
            font-weight: bold;
            color: #32adc6;
            font-size: 14px;
        }

        .zui-logo-line {
            width: 1px;
            height: 30px;
            background: #32adc6;
            display: inline-block;
            position: relative;
            top: 15px;
            left: 5px;
        }
    }
}
</style>

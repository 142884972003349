<template>
  <button class="zui-button" :class="{button_btna:countText == countText1}" @click="onClick">
    <slot></slot>
  </button>
</template>
<script>
  import './scss/button.scss'
  export default {
    props: {
      countText: {
        default: ''
      },
      countText1: {
        default: ''
      },
    },
    methods: {
      onClick (e) {
        console.log('e',e)
        this.$emit('click', e)
      }
    }
  }
</script>

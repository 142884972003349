<template>
 <img alt="xiaoyanyun" class="zui-logo" :src="$store.state.fromObj.icon" @click="onClick"/>
</template>
<script>
  import './scss/logo.scss'
  import logo from './img/logo.png'
  export default {
    name: 'Logo',
    props: {
      type: {
        default: 'xiaoyanyun'
      },
      href: {
        default: '/HomePage'
      }
    },
    computed: {

    },
    methods: {
      onClick: function (e) {
        if(this.type==="xiaoyanyun"){
           location.assign(this.href)
        }
      }
    }
  }
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersiste from 'vue-savedata'
import axios from "axios";
import VueViewer from 'v-viewer'

const persiste = createPersiste({
    // 加密存本地, 默认为false
    ciphertext: true,
    mode: 'SS',

})
Vue.use(Vuex)
Vue.use(VueViewer)

export default new Vuex.Store({
    state: {
        fromObj:{},
        countText:'首页',
        language:'zh'
    },
    mutations: {
        setfrom(state,setfrom) {
            state.fromObj = setfrom
        },
        setcountText(state,countText) {
            state.countText = countText
        },
        setLanguage(state,language) {
            state.language = language
        },
    },
    actions: {

    },
    plugins: [persiste],
})

export default {
    menu: {
        home: '首页',
        about: '关于我们',
        recommendation: '产品推荐',
        news:'新闻中心',
        inquiry:'产品查询',
        download:'资料下载',
        us:'联系我们',
        Hotline:'服务热线',
        more:'更多',
        read:'阅读详情',
        please:'请输入关键词'

    },
    about:{
        profile:'公司简介',
        certification:'公司认证',
        capacity:'生产能力',
        cooperation:'品牌合作',
        period:'成立年限',
        customer:'合作客户',
        team:'服务团队'
    },
    download:{
        size:'文件大小',
        download:'次下载',
        time:'更新时间',
        downloadN:'下载'
    },
    connection:{
        us:'联系我们',
        telephone:'电话',
        wechat:'微信',
        phone:'手机号',
        qq:'QQ',
        address:'公司地址',
        account:'微信公众号',
        code:'二维码'
    },
    Inquire:{
        main_number:'主号码',
        image:'图片',
        size:'尺寸',
        CarDealer:'汽车商（英文）',
        NoteType:'备注车型',
        ChineseName:'中文名称',
        numberSearch:'按号码查询',
        typeSearch:'按车型查询',
        sizeSearch:'按刹车片尺寸查询',
        wide:'宽度',
        height:'高度',
        thickness:'厚度',
        interval:'两个值的间隔不要大于5毫米',
    }
}

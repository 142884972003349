<template>
  <footer class="zui-footer">
    <div class="center">
      <img :src="$store.state.fromObj.icon1">
      <p class="copy-right">
        网站版权{{ $store.state.fromObj.copyright }}
        <br>
          {{ $store.state.fromObj.filings }}
      </p>
    </div>
    <div >
      <div class="links">
          <div @click="onButtonClick('首页')">{{  $t('menu.home') }}</div>
          <div @click="onButtonClick('关于我们')">{{  $t('menu.about') }}</div>
          <div @click="onButtonClick('产品推荐')">{{  $t('menu.recommendation') }}</div>
          <div @click="onButtonClick('新闻中心')">{{  $t('menu.news') }}</div>
          <div @click="onButtonClick('产品查询')">{{  $t('menu.inquiry') }}</div>
          <div @click="onButtonClick('资料下载')">{{  $t('menu.download') }}</div>
          <div @click="onButtonClick('联系我们')">{{  $t('menu.us') }}</div>
      </div>
      <div class="f-r f-n">
        <img class="ewm mt-10" :src="$store.state.fromObj.wechat_qrcode">
        <div class="box1">{{  $t('connection.account') }}</div>
      </div>
    </div>
  </footer>
</template>
<script>
  import './scss/footer.scss'
  import ZuiButton from "@/components/Button.vue";
  export default {
      components: {ZuiButton},
      methods:{
          onButtonClick: function (e) {
              if (e=="首页"){
                  this.$router.push({
                      name: 'HomePage'
                  })
              } else if (e=="关于我们"){
                  this.$router.push({
                      name: 'AboutUs'
                  })
              } else if (e=="产品推荐"){
                  this.$router.push({
                      name: 'Recommended'
                  })
              } else if (e=="新闻中心"){
                  this.$router.push({
                      name: 'NewsCenter'
                  })
              }  else if (e=="产品查询"){
                  this.$router.push({
                      name: 'Inquire'
                  })
              } else if (e=="资料下载"){
                  this.$router.push({
                      name: 'Download'
                  })
              } else {
                  this.$router.push({
                      name: 'Connection'
                  })
              }
              this.$store.commit('setcountText',e)
              window.scrollTo(0,0);
          },
      }
  }
</script>

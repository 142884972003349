export default {
    //页面的英文内容
    menu: {
        home: 'Home',
        about: 'About Us',
        recommendation: 'Product',
        news:'News',
        inquiry:'Products',
        download:'Data Download',
        us:'Contact Us',
        Hotline:'Hotline',
        more:'LEAEN MORE',
        read:'Read More',
        please:'Please Enter Keyword'
    },
    about:{
        profile:'Company Profile',
        certification:'Company Certification',
        capacity:'Productive Capacity',
        cooperation:'Brand Cooperation',
        period:'Establishment Period',
        customer:'Cooperative Customer',
        team:'Service Team'
    },
    download:{
        size:'File Size',
        download:'Download',
        time:'Update Time',
        downloadN:'Download'
    },
    connection:{
        us:'Contact Us',
        telephone:'telephone',
        wechat:'wechat',
        phone:'phone',
        qq:'QQ',
        address:'Company Address',
        account:'Wechat Public Account',
        code:'Code'
    },
    Inquire:{
        main_number:'Major number',
        image:'picture',
        size:'size',
        CarDealer:'Car dealer (English)',
        NoteType:'Note type',
        ChineseName:'Chinese name',
        numberSearch:'Search by number',
        typeSearch:'Query by vehicle type',
        sizeSearch:'Check by brake pad size',
        wide:'width',
        height:'height',
        thickness:'thickness',
        interval:'The two values should not be more than 5 mm apart',
    }
}

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 解决vue-router在3.0版本以上重复点击的报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/HomePage',
        name:'HomePage',
        component: () => import( '../views/HomePage.vue'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/AboutUs',
        name:'AboutUs',
        component: () => import( '../views/AboutUs.vue'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/NewsCenter',
        name:'NewsCenter',
        component: () => import( '../views/NewsCenter.vue'),
        meta: {
            title: '新闻中心'
        }
    },
    {
        path: '/NewsCenterDetails',
        name:'NewsCenterDetails',
        component: () => import( '../views/NewsCenterDetails.vue'),
        meta: {
            title: '新闻中心详情'
        }
    },
    {
        path: '/Recommended',
        name:'Recommended',
        component: () => import( '../views/Recommended.vue'),
        meta: {
            title: '产品推荐'
        }
    },
    {
        path: '/Inquire',
        name:'Inquire',
        component: () => import( '../views/Inquire.vue'),
        meta: {
            title: '产品查询'
        }
    },
    {
        path: '/Download',
        name:'Download',
        component: () => import( '../views/Download.vue'),
        meta: {
            title: '资料下载'
        }
    },
    {
        path: '/Connection',
        name:'Connection',
        component: () => import( '../views/Connection.vue'),
        meta: {
            title: '联系我们'
        }
    },

]



const router = new VueRouter({
    mode: 'hash',
    routes,
    // scrollBehavior: () => {
    //     history.pushState(null, null, document.URL)
    // }
})

export default router

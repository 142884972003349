<template>
  <div class="zui-phone">
    <div class="phone1 flex-center">
      <img src="./img/phone.png">
        {{  $t('menu.Hotline') }}
    </div>
    <div class="phone2 flex-center">
      {{ $store.state.fromObj.minister_tel }}
    </div>
  </div>
</template>
<script>
import './scss/phone.scss'

export default {}
</script>

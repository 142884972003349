<template>
  <div class="zui-loading">
    <div class="zui-loading-content">
      <div class="zui-loading-dot white"></div>
      <div class="zui-loading-dot"></div>
      <div class="zui-loading-dot"></div>
      <div class="zui-loading-dot"></div>
      <div class="zui-loading-dot"></div>
    </div>
  </div>
</template>
<script>
  import './scss/loading.scss'
  export default {}
</script>
